import { Injectable } from '@angular/core';
import { MessageCampaignState } from './message-campaigns';
import { CoolLocalStorage } from '@angular-cool/storage';

export const MESSAGE_CAMPAIGNS_STORAGE_KEY = 'message-campaigns';

@Injectable()
export class MessageCampaignStateManager {
  constructor(private _localStorage: CoolLocalStorage) {
  }

  public saveShown(campaignId: string) {
    const state = this.getCampaignState(campaignId);

    state.lastShown = new Date();
    state.numberOfShows++;

    this._setCampaignState(state);
  }

  public increaseClickCount(campaignId: string) {
    const state = this.getCampaignState(campaignId);

    state.numberOfClicks++;

    this._setCampaignState(state);
  }

  public getCampaignState(campaignId: string): MessageCampaignState {
    const states = this._getCampaignStateContainer();

    if (states[campaignId]) {
      if (states[campaignId].lastShown) {
        states[campaignId].lastShown = new Date(<string><any>states[campaignId].lastShown);
      }

      return states[campaignId];
    }

    return {
      campaignId: campaignId,
      lastShown: null,
      numberOfShows: 0,
      numberOfClicks: 0,
    };
  }

  private _setCampaignState(campaignState: MessageCampaignState) {
    const container = this._getCampaignStateContainer();

    container[campaignState.campaignId] = campaignState;

    this._localStorage.setObject(MESSAGE_CAMPAIGNS_STORAGE_KEY, container);
  }

  private _getCampaignStateContainer(): {
    [id: string]: MessageCampaignState;
  } {
    return this._localStorage.getObject<{
      [id: string]: MessageCampaignState;
    }>(MESSAGE_CAMPAIGNS_STORAGE_KEY) || {};
  }
}
