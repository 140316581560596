<div class="flex-row-center align-items-baseline"
     [class.black]="color === 'black'"
     [class.white]="color === 'white'">
  <ng-container *ngIf="size === 'default'">
    <div class="quest">Quest</div>

    <div class="ml-2">Companion</div>
  </ng-container>
  <ng-container *ngIf="size === 'small'">
    <div class="quest">Q</div>

    <div class="ml-2">C</div>
  </ng-container>
</div>


