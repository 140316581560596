export enum RollOutcomeType {
  Triumph = 'triumph',
  Success = 'success',
  ToughChoice = 'toughChoice',
  Failure = 'failure',
  Catastrophe = 'catastrophe',
}

export interface RollOutcome {
  type: RollOutcomeType;
  name: string;
  description: string;
}

export const ROLL_OUTCOMES: {
  [type in RollOutcomeType]: RollOutcome;
} = {
  [RollOutcomeType.Triumph]: { type: RollOutcomeType.Triumph, name: 'Triumph', description: 'The character automatically succeeds at what they were trying to do, and may find added fortune from The Guide. Damage dealt is doubled.' },
  [RollOutcomeType.Success]: { type: RollOutcomeType.Success, name: 'Success', description: 'The character accomplishes what they were trying to do without any compromises. Standard damage is dealt.' },
  [RollOutcomeType.ToughChoice]: { type: RollOutcomeType.ToughChoice, name: 'Tough choice', description: 'The character succeeds in what they were trying to do, but there’s a cost. If a party member rolls a tough choice, The Guide offers two setbacks to choose from.' },
  [RollOutcomeType.Failure]: { type: RollOutcomeType.Failure, name: 'Failure', description: 'The character fails. The Guide chooses a setback for the failure.' },
  [RollOutcomeType.Catastrophe]: { type: RollOutcomeType.Catastrophe, name: 'Catastrophe', description: 'The character automatically fails and The Guide may create a severe setback.' },
};

export function calculateRollOutcome(rollValue: number): RollOutcome {
  if (rollValue >= 20) {
    return ROLL_OUTCOMES[RollOutcomeType.Triumph];
  }

  if (rollValue > 10 && rollValue < 20) {
    return ROLL_OUTCOMES[RollOutcomeType.Success];
  }

  if (rollValue > 5 && rollValue < 11) {
    return ROLL_OUTCOMES[RollOutcomeType.ToughChoice];
  }

  if (rollValue > 1 && rollValue < 6) {
    return ROLL_OUTCOMES[RollOutcomeType.Failure];
  }

  return ROLL_OUTCOMES[RollOutcomeType.Catastrophe];
}