<div class="side-menu" [class.open]="isOpen">
  <div>
    <button mat-icon-button class="mat-icon-button-big text-white" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="mt-3">
    <div (click)="close()">
      <a class="nav-item" [routerLink]="adventuresUrl" routerLinkActive="active">Adventures</a>
    </div>

    <div (click)="close()">
      <a class="nav-item" [routerLink]="campaignsUrl" routerLinkActive="active">Campaigns</a>
    </div>

    <div (click)="close()">
      <a class="nav-item" [routerLink]="charactersUrl" routerLinkActive="active">Characters</a>
    </div>

    <div (click)="close()">
      <a class="nav-item" [routerLink]="npcsUrl" routerLinkActive="active">NPCs</a>
    </div>

    <div (click)="close()">
      <a class="nav-item" [routerLink]="itemsUrl" routerLinkActive="active">Items</a>
    </div>

    <div (click)="close()">
      <a class="nav-item" [routerLink]="worldsUrl" routerLinkActive="active">Worlds</a>
    </div>

    <mat-divider class="mt-3 mat-divider-white"></mat-divider>

    <div (click)="rollDiceAsync(); close()" class="mt-3">
      <fa-icon icon="dice-d20" class="mr-2"></fa-icon>

      Roll
    </div>

    <div (click)="toughChoiceAsync(); close()" class="mt-3">
      <fa-icon icon="certificate" class="mr-2"></fa-icon>

      Choices
    </div>

    <mat-divider class="my-3 mat-divider-white"></mat-divider>

    <div *ngIf="isUserLoggedIn" class="pt-3">
      <app-account-menu></app-account-menu>
    </div>

    <ng-container *ngIf="!isUserLoggedIn">
      <a class="nav-item" [routerLink]="loginUrl" (click)="close()">Sign in</a>

      <a class="nav-item nav-item-bordered" [routerLink]="registerUrl" (click)="close()">Sign up</a>
    </ng-container>
  </div>
</div>
