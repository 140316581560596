import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ENVIRONMENT, WINDOW } from './services/injection-tokens';
import { environment } from '../../environments/environment';
import { RouteNavigationService } from './routing/route-navigation.service';
import { LogoComponent } from './components/logo/logo.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from './services/logger/logger.service';
import { LoggedInRouteGuard } from './routing/route-guards/logged-in.guard';
import { TrackingService } from './services/tracking/tracking.service';
import { CoolStorageModule } from '@angular-cool/storage';
import { AuthenticationService } from './services/authentication.service';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { Angulartics2Module } from 'angulartics2';
import { MessageBusService } from './services/message-bus/message-bus.service';
import { CoolHttpModule } from '@angular-cool/http';
import { IfFeatureEnabledDirective } from './directives/if-feature-enabled.directive';
import { FeatureSwitchRouteGuard } from './routing/route-guards/feature-switch.guard';
import { UserSettingsService } from './services/user-settings.service';
import { DebounceDirective } from './directives/debounce.directive';

@NgModule({
  declarations: [
    LogoComponent,
    IfFeatureEnabledDirective,
    DebounceDirective,
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    RouterModule,
    FontAwesomeModule,

    CoolStorageModule,
    CoolHttpModule,
    Angulartics2Module.forRoot(),

    MatButtonModule,
    MatIconModule,
    MatDividerModule,
  ],
  exports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    RouterModule,

    LogoComponent,
    IfFeatureEnabledDirective,
    DebounceDirective,

    FontAwesomeModule,

    Angulartics2Module,
    CoolStorageModule,
    CoolHttpModule,

    MatButtonModule,
    MatIconModule,
    MatDividerModule,
  ],
})
export class AppCommonModule {
  public static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        LoggerService,
        TrackingService,
        RouteNavigationService,
        AuthenticationService,
        UserSettingsService,

        LoggedInRouteGuard,
        FeatureSwitchRouteGuard,

        MessageBusService,

        ...<Provider[]>CoolHttpModule.forRoot().providers,
        ...<Provider[]>CoolStorageModule.forRoot().providers,

        { provide: ENVIRONMENT, useValue: environment },
        { provide: WINDOW, useValue: window },
      ],
    };
  }
}
