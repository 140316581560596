export enum FeatureSwitch {
  Characters = 'characters',

  Worlds = 'worlds',

  NPCs = 'npcs',

  Campaigns = 'campaigns',

  EditBuiltInAdventures = 'edit-built-in-adventures',

  CreateAdventure = 'create-adventure',
  PrivateAdventures = 'private-adventures',
  PayedAdventures = 'paid-adventures',
  ViewAdventures = 'view-adventures',

  Items = 'items',
  ItemCollections = 'item-collections',
}