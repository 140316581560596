import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { UserSettingsDTO } from '../../../../../server/src/settings/dto/user-settings.dto';
import { UserDTO } from '../../../../../server/src/users/dto/users.dto';
import { FileUploadRequestUtils } from '../utils/file-upload-request.utils';
import { FileDTO } from '../../../../../server/src/files/dto/file.dto';

@Injectable()
export class UserSettingsService {
  constructor(private _http: CoolHttp) {
  }

  public async getUserSettingsAsync(): Promise<UserSettingsDTO> {
    return await this._http.getAsync<UserSettingsDTO>('api/settings');
  }


  public async updateUserSettingsAsync(settings: { user?: UserDTO }): Promise<void> {
    const formData = FileUploadRequestUtils.createRequestForm(
      'settings',
      settings,
      (settings) => <FileDTO>settings.user?.photo,
    );

    return await this._http.postAsync('api/settings', formData);
  }
}
