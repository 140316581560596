import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { RouteNavigationService } from '../../common/routing/route-navigation.service';
import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT } from '../../common/services/injection-tokens';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  constructor(private _routeNavigationService: RouteNavigationService,
              @Inject(ENVIRONMENT) private _environment: Environment) {
  }

  public year = new Date().getFullYear();

  public privacyUrl: string = this._routeNavigationService.getUrlForPrivacyPolicy();
  public termsUrl: string = this._routeNavigationService.getUrlForTermsAndConditions();
  public pricingUrl: string = this._routeNavigationService.getUrlForPricing();

  public socialLinks = this._environment.social;

  ngOnInit(): void {

  }
}
