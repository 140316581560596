<div class="p-2 dice-dialog">
  <h2 class="text-center mb-3">D20 dice roll</h2>

  <div class="position-relative text-center p-3">
    <fa-icon icon="dice-d20" size="10x"></fa-icon>

    <div class="value">{{ rollValue }}</div>
  </div>

  <h3 class="mt-2 text-center">{{ rollOutcome?.name }}</h3>

  <div class="text-muted mt-2">{{ rollOutcome?.description }}</div>

  <div class="mt-4 text-center">
    <button mat-raised-button color="primary" (click)="roll()">Re-roll</button>
    <button mat-raised-button class="ml-3" (click)="close()">Close</button>
  </div>
</div>
