export enum RoutePage {
  CharacterList,
  CharacterPage,
  CharacterCreator,

  WorldList,
  WorldPage,
  WorldCreator,

  CampaignList,
  CampaignPage,
  CampaignSessionPage,
  CampaignAdventureEpisodePage,

  NPCList ,
  NPCPage,

  ItemList ,
  ItemPage,

  AdventuresList,
  AdventurePage,
  AdventureEpisodePage,
}
