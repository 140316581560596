import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { Store } from '@ngxs/store';
import { ApplicationStateModel, StoreState } from '../../../app.state';
import { filter } from 'rxjs/operators';
import { UserDTO } from '../../../../../../server/src/users/dto/users.dto';

@Injectable()
export class LoggerService {
  constructor(private _store: Store,
              @Inject(ENVIRONMENT) private _environment: Environment) {
    this._store.select<ApplicationStateModel>((state: StoreState) => state.applicationState)
      .pipe(
        filter(state => !!state),
      )
      .subscribe(appState => {
        this._onUserLoggedIn(appState.user);
      });
  }

  public initialize() {
    if (this._environment.logging?.enabled) {
      Sentry.init({
        dsn: this._environment.logging.dsn,
        autoSessionTracking: true,
      });
    }
  }

  public error(error: Error, data?: any) {
    console.error(error, data);

    if (data) {
      this.captureBreadcrumb('error-data', data);
    }

    if ((<any>error).data) {
      try {
        this.captureBreadcrumb(error.message, JSON.stringify((<any>error).data));
      } catch {
        console.log('Failed to capture breadcrumb');
      }
    }

    if ((<any>error).body) {
      try {
        this.captureBreadcrumb(error.message, JSON.stringify((<any>error).body));
      } catch {
        console.log('Failed to capture breadcrumb');
      }
    }

    Sentry.captureException(error);
  }

  public captureBreadcrumb(message: string, data: any = null) {
    Sentry.addBreadcrumb({
      message,
      data,
    });
  }

  private _onUserLoggedIn(user: UserDTO | undefined) {
    Sentry.setContext('user', {
      id: user?.id,
    });
  }
}

