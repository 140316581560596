import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,

  backendUrl: 'https://api.questcompanion.com',

  logging: {
    enabled: true,
    dsn: 'https://94ca323f5f15407d8a3f219c6e6f706c@o493900.ingest.sentry.io/5563964',
  },

  tracking: {
    enabled: true,
  },

  social: {
    patreonUrl: 'https://www.patreon.com/questcompanion',
    trelloUrl: 'https://trello.com/b/jq8HgpcL',
    twitterUrl: 'https://twitter.com/QuestCompanion',
    discordUrl: 'https://discord.gg/3K39AWXznK',
    emailUrl: 'mailto:info@questcompanion.com',
  },
};
