import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteLocations } from './route-locations.enum';
import { CoolLocalStorage } from '@angular-cool/storage';
import { ENVIRONMENT, WINDOW } from '../services/injection-tokens';
import { Environment } from '../../../environments/environment.interface';

const SAVED_LOGIN_URL_STORAGE_KEY = 'after-login';

@Injectable()
export class RouteNavigationService {
  constructor(private _localStorage: CoolLocalStorage,
              @Inject(WINDOW) private _window: Window,
              @Inject(ENVIRONMENT) private _environment: Environment,
              private _router: Router) {
  }

  public async goToPricingPageAsync() {
    await this.goToSubscriptionCheckoutAsync();
  }

  public async goToSubscriptionCheckoutAsync() {
    this._window.open(this._environment.social?.patreonUrl, '_blank');
  }

  public saveRouteToNavigateAfterLogin(url: string) {
    this._localStorage.setItem(SAVED_LOGIN_URL_STORAGE_KEY, url);
  }

  public async redirectAfterSuccessfulLoginAsync() {
    const url = this._localStorage.getItem(SAVED_LOGIN_URL_STORAGE_KEY);

    if (url) {
      this._localStorage.removeItem(SAVED_LOGIN_URL_STORAGE_KEY);

      await this._router.navigateByUrl(url);
    } else {
      await this._router.navigateByUrl(this.getUrlForCharacters());
    }
  }

  public async goToProfileSettingsPageAsync() {
    await this._router.navigateByUrl(this.getUrlForProfileSettings());
  }

  public async goToCharacterPageAsync(characterId: string) {
    await this._router.navigateByUrl(this.getUrlForPlayerCharacterPage(characterId));
  }

  public async goToCharacterCreatorAsync(characterId?: string) {
    await this._router.navigate(this.getUrlForCharacterCreator().split('/'), {
      queryParams: {
        character: characterId,
      },
    });
  }

  public async goToWorldPageAsync(worldId: string) {
    await this._router.navigateByUrl(this.getUrlForWorldPage(worldId));
  }

  public async goToWorldCreatorAsync(worldId?: string) {
    await this._router.navigate(this.getUrlForWorldCreator().split('/'), {
      queryParams: {
        world: worldId,
      },
    });
  }

  public async goToCampaignListAsync() {
    await this._router.navigateByUrl(this.getUrlForCampaigns());
  }

  public async goToCampaignPageAsync(campaignId: string, isEditMode: boolean = false) {
    const queryParams: any = {};

    if (isEditMode) {
      queryParams.edit = true;
    }

    await this._router.navigate(this.getUrlForCampaignPage(campaignId).split('/'), {
      queryParams: queryParams,
    });
  }

  public async goToCampaignSessionPageAsync(campaignId: string, sessionId: string, isEditMode: boolean = false) {
    const queryParams: any = {};

    if (isEditMode) {
      queryParams.edit = true;
    }

    await this._router.navigate(this.getUrlForCampaignSessionPage(campaignId, sessionId).split('/'), {
      queryParams: queryParams,
    });
  }

  public async goToNPCListAsync() {
    await this._router.navigateByUrl(this.getUrlForNPCList());
  }

  public async goToNPCPageAsync(npcId: string, isEditMode: boolean = false) {
    const queryParams: any = {};

    if (isEditMode) {
      queryParams.edit = true;
    }

    await this._router.navigate(this.getUrlForNPCPage(npcId).split('/'), {
      queryParams: queryParams,
    });
  }

  public async goToItemListAsync() {
    await this._router.navigateByUrl(this.getUrlForItems());
  }

  public async goToItemPageAsync(itemId: string, isEditMode: boolean = false) {
    const queryParams: any = {};

    if (isEditMode) {
      queryParams.edit = true;
    }

    await this._router.navigate(this.getUrlForItemPage(itemId).split('/'), {
      queryParams: queryParams,
    });
  }

  public async goToLoginAsync(redirectToCurrentUrl: boolean) {
    if (redirectToCurrentUrl) {
      this.saveRouteToNavigateAfterLogin(this._router.url);
    }

    await this._router.navigateByUrl(this.getUrlForLogin());
  }

  public async goToNotFoundAsync() {
    await this._router.navigateByUrl(`/${ RouteLocations.NotFound }`);
  }

  public getUrlForLanding() {
    return '/' + [
      RouteLocations.Home,
      RouteLocations.HomeChildren.Landing,
    ].join('/');
  }

  public getUrlForLogin() {
    return '/' + [
      RouteLocations.Login,
      RouteLocations.LoginChildren.LoginPage,
    ].join('/');
  }

  public getUrlForPricing() {
    return '/' + [
      RouteLocations.Home,
      RouteLocations.HomeChildren.Pricing,
    ].join('/');
  }

  public getUrlForRegistration() {
    return '/' + [
      RouteLocations.Login,
      RouteLocations.LoginChildren.Registration,
    ].join('/');
  }

  public getUrlForPrivacyPolicy() {
    return '/' + [
      RouteLocations.About,
      RouteLocations.AboutChildren.PrivacyPolicy,
    ].join('/');
  }

  public getUrlForTermsAndConditions() {
    return '/' + [
      RouteLocations.About,
      RouteLocations.AboutChildren.TermsAndConditions,
    ].join('/');
  }

  public getUrlForProfileSettings() {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Profile,
    ].join('/');
  }

  public getUrlForCharacters() {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Characters,
    ].join('/');
  }

  public getUrlForWorlds() {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Worlds,
    ].join('/');
  }

  public getUrlForPlayerCharacterPage(playerCharacterId: string): string {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Characters,
      RouteLocations.CompanionChildren.CharactersChildren.PlayerCharacter.replace(':characterId', playerCharacterId),
    ].join('/');
  }

  public getUrlForCharacterCreator(characterId?: string | undefined): string {
    let url = '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Characters,
      RouteLocations.CompanionChildren.CharactersChildren.Creator,
    ].join('/');

    if (characterId) {
      url += `?characterId=${ characterId }`;
    }

    return url;
  }

  public getUrlForWorldPage(worldId: string): string {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Worlds,
      RouteLocations.CompanionChildren.WorldsChildren.World.replace(':worldId', worldId),
    ].join('/');
  }

  public getUrlForWorldCreator(worldId?: string | undefined): string {
    let url = '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Worlds,
      RouteLocations.CompanionChildren.WorldsChildren.Creator,
    ].join('/');

    if (worldId) {
      url += `?worldId=${ worldId }`;
    }

    return url;
  }

  public getUrlForCampaigns() {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Campaigns,
    ].join('/');
  }

  public getUrlForCampaignPage(campaignId: string): string {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Campaigns,
      RouteLocations.CompanionChildren.CampaignsChildren.Campaign.replace(':campaignId', campaignId),
    ].join('/');
  }

  private getUrlForCampaignSessionPage(campaignId: string, sessionId: string) {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Campaigns,
      RouteLocations.CompanionChildren.CampaignsChildren.CampaignSession.replace(':campaignId', campaignId).replace(':sessionId', sessionId),
    ].join('/');
  }

  public getUrlForNPCList() {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.NPCs,
    ].join('/');
  }

  public getUrlForNPCPage(npcId: string): string {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.NPCs,
      RouteLocations.CompanionChildren.NPCsChildren.NPCPage.replace(':npcId', npcId),
    ].join('/');
  }

  public getUrlForAdventures() {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Adventures,
    ].join('/');
  }

  public getUrlForItems() {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Items,
    ].join('/');
  }

  public getUrlForItemPage(itemId: string): string {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Items,
      RouteLocations.CompanionChildren.ItemsChildren.ItemPage.replace(':itemId', itemId),
    ].join('/');
  }

  public getUrlForAdventurePage(adventureId: string): string {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Adventures,
      RouteLocations.CompanionChildren.AdventuresChildren.AdventurePage.replace(':adventureId', adventureId),
    ].join('/');
  }

  public async goToAdventurePageAsync(adventureId: string) {
    await this._router.navigateByUrl(this.getUrlForAdventurePage(adventureId));
  }

  public getUrlForAdventureEpisodePage(adventureId: string, episodeId: string): string {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Adventures,
      RouteLocations.CompanionChildren.AdventuresChildren.AdventureEpisodePage.replace(':adventureId', adventureId).replace(':episodeId', episodeId),
    ].join('/');
  }

  public async goToAdventureEpisodePageAsync(adventureId: string, episodeId: string) {
    await this._router.navigateByUrl(this.getUrlForAdventureEpisodePage(adventureId, episodeId));
  }

  public getUrlForCampaignAdventureEpisodePage(campaignId: string, episodeId: string): string {
    return '/' + [
      RouteLocations.Companion,
      RouteLocations.CompanionChildren.Campaigns,
      RouteLocations.CompanionChildren.CampaignsChildren.CampaignAdventureEpisodePage.replace(':campaignId', campaignId).replace(':episodeId', episodeId),
    ].join('/');
  }

  public async goToCampaignAdventureEpisodePageAsync(campaignId: string, episodeId: string) {
    await this._router.navigateByUrl(this.getUrlForCampaignAdventureEpisodePage(campaignId, episodeId));
  }

  public async goToAdventureListAsync() {
    await this._router.navigateByUrl(this.getUrlForAdventures());
  }
}
