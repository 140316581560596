import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { TrackingUserInfo } from './tracking.interface';
import {
  Angulartics2,
  Angulartics2Amplitude,
  Angulartics2GoogleGlobalSiteTag,
  Angulartics2Mixpanel,
} from 'angulartics2';
import { Store } from '@ngxs/store';
import { ApplicationStateModel, StoreState } from '../../../app.state';
import { MessageBusService } from '../message-bus/message-bus.service';
import { TrackingEventCategory, TrackingEvents } from './tracking-events';
import { Message, MessageType } from '../message-bus/message';

@Injectable()
export class TrackingService {
  constructor(@Inject(ENVIRONMENT) private _environment: Environment,
              private _store: Store,
              private _messageBus: MessageBusService,
              private _angulartics2: Angulartics2,
              private _googleTagManagerAnalytics: Angulartics2GoogleGlobalSiteTag,
              private _amplitudeAnalytics: Angulartics2Amplitude,
              private _mixPanelAnalytics: Angulartics2Mixpanel) {
  }

  public initialize() {
    if (!this._environment?.tracking?.enabled) {
      return;
    }

    this._initializeProviders();

    this._store.select<ApplicationStateModel>((state: StoreState) => state.applicationState)
      .subscribe((state: ApplicationStateModel) => {
        if (state.user) {
          this.identify({
            id: state.user.id,
          });
        }
      });

    for (const trackingEvent of TrackingEvents) {
      this._messageBus.subscribe(trackingEvent.triggerMessageType)
        .subscribe((message: Message<MessageType>) => {
          this.trackEvent(trackingEvent.eventName, trackingEvent.eventCategory, trackingEvent.passPayload ? message.payload : undefined);
        });
    }
  }

  public identify(userInfo: TrackingUserInfo) {
    if (!this._environment?.tracking?.enabled) {
      return;
    }

    if (userInfo?.id) {
      this._angulartics2.setUsername.next(userInfo.id);
    }

    this._angulartics2.setUserProperties.next(userInfo);
  }

  public trackEvent(name: string, category: TrackingEventCategory, properties?: any) {
    if (!this._environment?.tracking?.enabled) {
      return;
    }

    this._angulartics2.eventTrack.next({
      action: name,
      properties: {
        category: category,
        ...properties,
      },
    });
  }

  private _initializeProviders() {
    this._googleTagManagerAnalytics.startTracking();
    this._amplitudeAnalytics.startTracking();
    this._mixPanelAnalytics.startTracking();
  }
}
