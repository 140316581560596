<div class="tough-choices p-2">
  <h2 class="text-center mb-4">Tough choices</h2>

  <div>
    <div *ngFor="let choice of choices" class="d-md-flex flex-row align-items-center text-center mb-3">
      <div class="flex-grow-1 card-box mr-3">
        {{ choice.value }}
      </div>
      <div>
        <button mat-raised-button (click)="reGenerateChoice(choice)" class="mt-2 mt-md-0">
          <fa-icon icon="sync-alt"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="mt-4 text-center">
    <button mat-raised-button (click)="close()">Close</button>
  </div>
</div>
