<header class="flex-row-center px-3 px-xl-5">
  <div class="flex-grow-1 flex-row-center align-items-baseline">
    <div class="flex-row-center hover-fade mr-2 mr-xl-3" [routerLink]="landingUrl">
      <app-logo color="black"></app-logo>
    </div>

    <div class="flex-row-center flex-grow-1 d-none d-xl-flex">
      <a class="nav-item" [routerLink]="adventuresUrl" routerLinkActive="active">
        Adventures
      </a>

      <a class="nav-item" [routerLink]="campaignsUrl" routerLinkActive="active">
        Campaigns
      </a>

      <a class="nav-item" [routerLink]="charactersUrl" routerLinkActive="active">
        Characters
      </a>

      <a class="nav-item" [routerLink]="npcsUrl" routerLinkActive="active">
        NPCs
      </a>

      <a class="nav-item" [routerLink]="itemsUrl" routerLinkActive="active">
        Items
      </a>

      <a class="nav-item" [routerLink]="worldsUrl" routerLinkActive="active">
        Worlds
      </a>

      <div class="flex-grow-1 flex-row-center justify-content-end">
        <button mat-stroked-button class="mr-2 mat-stroked-button-square"
                title="Generate tough choices!"
                (click)="toughChoiceAsync()">
          <fa-icon icon="certificate" size="lg"></fa-icon>

          <span class="ml-2">Choices</span>
        </button>

        <button mat-stroked-button class="mr-4 mat-stroked-button-square"
                title="Roll the dice!"
                (click)="rollDiceAsync()">
          <fa-icon icon="dice-d20" size="lg"></fa-icon>

          <span class="ml-2">Roll</span>
        </button>

        <button *ngIf="isUserLoggedIn" mat-stroked-button class="mat-stroked-button-square" style="padding-bottom: 1px">
          <app-account-menu></app-account-menu>
        </button>

        <ng-container *ngIf="!isUserLoggedIn">
          <a class="nav-item" [routerLink]="loginUrl">
            Sign in
          </a>

          <a class="nav-item nav-item-bordered ml-3" [routerLink]="registerUrl">
            Sign up
          </a>
        </ng-container>
      </div>
    </div>

    <div class="flex-grow-1 text-right d-xl-none">
      <button mat-icon-button class="mat-icon-button-big mat-elevation-z2" (click)="sideMenu.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <app-side-menu #sideMenu></app-side-menu>
    </div>
  </div>
</header>
