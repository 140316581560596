import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { calculateRollOutcome, RollOutcome } from '../../../../../../common/dto/rolls.dto';
import { TriggerMessage } from '../../../common/decorators/trigger-message.decorator';
import { MessageType } from '../../../common/services/message-bus/message';

@Component({
  selector: 'app-dice-dialog',
  templateUrl: './dice-dialog.component.html',
  styleUrls: ['./dice-dialog.component.scss'],
})
export class DiceDialogComponent implements OnInit {
  constructor(private _dialogRef: MatDialogRef<DiceDialogComponent>) {
  }

  public rollValue?: number;
  public rollOutcome?: RollOutcome;

  public ngOnInit() {
    this._rollNewValue();
  }

  public close() {
    this._dialogRef.close();
  }

  @TriggerMessage(MessageType.DiceReRoll)
  public roll() {
    this._rollNewValue();
  }

  private _rollNewValue() {
    this.rollValue = 1 + Math.floor(Math.random() * 20);
    this.rollOutcome = calculateRollOutcome(this.rollValue);
  }
}
