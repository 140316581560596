export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const MINUTE_IN_MILLISECONDS = 1000 * 60;

export abstract class DateUtils {
  public static getDaysBetween(a: Date, b: Date): number {
    return Math.floor((b.getTime() - a.getTime()) / DAY_IN_MILLISECONDS);
  }

  public static getMinutesBetween(a: Date, b: Date): number {
    return Math.floor((b.getTime() - a.getTime()) / MINUTE_IN_MILLISECONDS);
  }
}
