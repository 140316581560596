import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { RouteNavigationService } from '../route-navigation.service';
import { Store } from '@ngxs/store';
import { ApplicationStateModel, StoreState } from '../../../app.state';

@Injectable()
export class LoggedInRouteGuard implements CanActivate {
  constructor(private _store: Store,
              private _routeNavigationService: RouteNavigationService) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const applicationState = this._store.selectSnapshot<ApplicationStateModel>((state: StoreState) => state.applicationState);

    if (!applicationState.user) {
      this._routeNavigationService.saveRouteToNavigateAfterLogin(state.url);

      this._routeNavigationService.goToLoginAsync(false);
    }

    return !!applicationState.user;
  }
}
