import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCommonModule } from './common/common.module';
import { NgxsModule } from '@ngxs/store';
import { ApplicationState } from './app.state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppCoreModule } from './core/core.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    AppCommonModule.forRoot(),
    AppCoreModule,

    NgxsModule.forRoot([ApplicationState]),

    FontAwesomeModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    (<any>window).injector = injector;
  }
}
