export const SUGGESTIONS = {
  ToughChoices: [
    'Lose an item from the inventory',
    'Lose a weapon',
    'Lose an armor',
    'Lose an action point',
    'Lose 2 action points',
    'Lose a hit point',
    'Lose 2 hit points',
    'Forget an ability',
    'Forget who you are for 10 minutes',
    'Forget what your goal is for 10 minutes',
    'NPS attacks the group',
    'You fall unconscious',
    'Your body is impared',
    'You\'re cursed',
    'You get a disease',
    'You offend someone',
    'You broke the law',
    'You become wanted for breaking the law',
    'You\'re cast out of the community',
    'You lose an ally',
    'There\'s a bounty on your head',
    'An item is stolen from you',
    'An item breaks',
    'A weapon breaks',
    'Your armor breaks',
    'A merchant demands a costly trade',
    'Weather makes the journey difficult',
    'A disaster devastates the land',
    'A plague spreads',
    'A boss gains power',
    'A boss gains knowledge',
    'An ally loses territory',
    'Invaders make a region hostile',
    'A town is destroyed',
    'Monsters spread across the land',
    'Deal only half damage',
    'Deal only half damage',
    'Deal on third damage',
    'Save only 1 friend instead of two',
    'Deal damage to your party member instead of the target',
  ],

  PlayerCharacter: {
    body: [
      'scales', 'worn scars', 'iridescent skin',
      'rack of muscles', 'towering physique', 'speckled complexion',
      'barrel-sized belly', 'head of tentacles', 'generous curves',
      'elongated limbs', 'bumpy exterior', 'willowy frame',
      'sculpted hair', 'stout stature', 'lived-in body',
      'vestigial tail', 'webbed fins', 'rough hide',
    ],

    face: [
      'gaunt face', 'sharp teeth', 'fulsome cheeks',
      'large eyes', 'pointy ears', 'vestigial antennae',
      'knee-length beard', 'devastating smile', 'windswept face',
      'manicured fuzz', 'ridged forehead', 'triangular head',
      'timeworn face', 'romantic eyes', 'severe jawline',
      'skeptical eyes', 'radiant smile', 'burning eyes', 'heavy brows',
    ],

    vibe: [
      'long shadow', 'sleepy mood', 'sparkling gaze',
      'eternal grimace', 'bursting energy', 'an air of mystery',
      'gentle disposition', 'androgynous vibes', 'thousand-yard stare',
      'tightly wound energy', 'brooding presence', 'friendly demeanor',
      'meandering gaze', 'graceful posture', 'captivating grin', 'raucous laugh',
      'flawless poise', 'fiery temper',
    ],

    outfit: [
      'etched leather armor', 'a billowing jumpsuit',
      'a tightly fitted tunic', 'religious vestments',
      'nicked chainmail', 'runes in my hair', 'a fluttering cape',
      'weathered rags', 'a layered dress', 'a warm cloak',
      'an owl pin', 'a charmed necklace', 'a ragged headcover',
      'antique eyeglasses', 'a patterned hijab',
      'a silken eyepatch', 'fingerless gloves',
      'a quilted jacket', 'encrusted cuffs', 'a feathered cap',
      'a boned bodice', 'a fancy hat', 'a bronze breastplate',
      'oversized spectacles', 'a homemade charm',
      'hammered earrings', 'an ornamented belt',
      'a shining hauberk', 'an animal brooch', 'obsidian bracers',
      'a symbol of god', 'a tarnished ring', 'a humble tunic',
    ],

    move: [
      'no sense of urgency', 'an effortless glide',
      'frenzied footwork', 'a confident step',
      'great difficulty', 'a reliable pace',
      'wide-swinging arms', 'a spring in my step',
      'a singular purpose', 'no sense of space',
      'music in my feet', 'an uneven gait',
      'a joyful whistle', 'relentless focus',
      'casual swagger', 'apprehension', 'a heavy step', 'fearlessness',
    ],

    location: [
      'a great metropolis', 'a remote village', 'a frontier town',
      'a lonely island', 'a capital city', 'a seastead', 'a remote stronghold',
      'a traveling caravan', 'a hidden warren', 'a working farm',
      'a roadside inn', 'a ship at sea', 'a place I can\'t name',
      'a subterranean city', 'a forgotten nation', 'a mountain town',
      'a city in the mist', 'a homestead',
    ],

    legacy: [
      'their steady pursuit of pleasure', 'their easygoing temperament',
      'their unhurried sense of time', 'treating strangers with love',
      'restoring justice to the land', 'once ruling a vast empire',
      'creating a world wonder', 'enduring a great tragedy',
      'their neutral rationality', 'their warm hospitality',
      'a culture of secrecy', 'non-hierarchical relationships',
      'plainly stating their intentions', 'their sense of duty to each other',
      'resisting a brutal ruling order', 'creating historic works of art',
      'strict adherence to the law', 'their commercial success', 'setting cultural trends',
      'their traditional ways', 'inventing the future', 'losing a great war',
    ],

    dream: [
      'returning to my hometown as a renowned hero',
      'freeing myself from a gang that wants me dead',
      'getting revenge on someone who wronged me',
      'finding a corner of the world to make my own',
      'publishing a book that\'s found in every home',
      'sparking an idea that transforms the world',
      'becoming the greatest scholar  in my field',
      'recovering a stolen artifact for my people',
      'stealing from the rich to give to the poor',
      'having my name spoken by my leader',
      'meeting my parents for the first time',
      'spreading my ideal across the land',
      'overturning a corrupt government',
      'producing a timeless work of art',
      'becoming tremendously wealthy',
      'finding the source of eternal life',
      'becoming a leader of my nation',
      'becoming a notorious gambler',
      'making every stranger smile',
      'becoming a master artisan',
      'dying an honorable death',
      'mapping the entire world',
      'meeting the grim reaper',
      'pulling off the big score',
      'traveling to the stars',
      'becoming a celebrity',
      'meeting my god(s)',
      'killing my past',
    ],

    commonWeapons: [
      'Sword', 'Axe', 'Hammer', 'Bow', 'Spear', 'Staff', 'Knife', 'Spear',
    ],
  },

  World: {
    name: [
      'First world', 'Dark world', 'Empire of the Sun', 'Dark lands', 'Puffy islands', 'Mountains', 'Islands',
    ],

    location: [
      'in the frontier town of Beritia',
      'outside the great forest',
      'in the great capital of Albetra',
      'Grand Fortress of Elentir',
      'in the heart of Boglir',
      'in the shadow of an oak tree',
      'on the island of Ibano',
      'in the costal city of Biantry',
      'on the borderlands',
      'on the hills',
      'on the fields',
      'at the gates',
    ],

    legacy: [
      'rough-and-tumble region',
      'place of great knowledge',
      'bustling trade hub',
      'lawless frontier',
      'besieged city',
      'magical land',
      'rebel stronghold',
      'land of mystery',
      'holy place',
      'devastated land',
    ],

    fromTo: [
      'towering summit of Mount Lion',
      'crumbling ruins of Utoristu',
      'fallow fields of Almone',
      'corrupted throne of Alfjue',
      'sunken city of Allositer',
      'shattered islands',
      'gates of hell',
      'chasm of Glutidor',
      'ice-capped peaks in the West',
      'forgotten forest in the North',
      'million lakes in the East',
      'shifting sands in the South',
      'infinite depths of the great sea',
    ],

    find: [
      'the survivors of the great war',
      'peace and prosperity',
      'traders and markets',
      'humble nomads',
      'a horde of the turned',
      'zombies',
      'dragons',
      'people ruled by an iron fist',
      'people guided by wizards',
      'pioneers looking for a home',
      'killers and thieves',
    ],

    magic: [
      'rare', 'common', 'outlawed', 'regulated', 'unknown', 'only known from stories', 'regarded non-existent',
      'only known to elders', 'given by an elixir', 'unpredictable',
    ],

    danger: [
      'everywhere', 'rarely seen', 'otherworldly', 'ghastly', 'a mystery', 'mundane', 'magical', 'oppressive',
      'bestial', 'pirates', 'bandits', 'bankers', 'wizards', 'witches',
    ],

    life: [
      'comfortable and convenient', 'abundant with pleasures', 'brutish and difficult', 'a grueling affair',
      'always a risk', 'filled with peace and love', 'raucous and competitive', 'a waking nightmare', 'humble and quiet', 'extravagant',
    ],

    similarTo: [
      'Oz', 'Narnia', 'Hybrodia', 'The Broken Empire', 'Randland', 'Westeros',
      'The Malazan Empire', 'Middle-Earth', 'Asgard', 'Azeroth', 'City of Light',
      'Dinotopia', 'The Enchanted Forest', 'Fabletown', 'Fillory', 'The Forgotten Realms',
      'Hogwarts', 'Hyrule', 'The Island', 'Lyra\'s World', 'Midgard', 'The mirror dimension',
    ],

    hope: [
      'the invaders will be defeated',
      'darkness will be snuffed out',
      'the revolution will succeed',
      'the long drought will end',
      'their families will return',
      'the savior is coming',
      'a new ruler will rise',
      'the king will return',
      'the world fair will bring wonders',
      'the gods will answer their prayers',
      'peace will be unbroken',
      'peace will be broken',
      'war will create new opportunities',
      'justice will be delivered',
    ],

    fear: [
      'the ruler is tightening their grip',
      'thugs are in control',
      'wizards will rise',
      'witches will return',
      'the king will return',
      'plague is spreading',
      'magic is fading',
      'magic is spreading',
      'a strange star haunts the sky',
      'madness is growing',
      'zealots speak of grim omens',
    ],

    adventureHook: [
      'You see a man across the street staring straight at you. You’ve never seen him before but he wears a lily on his coat–the same type of lily found near the victim last night…after a few moments of glaring he starts walking purposefully towards you. But he doesn’t notice the van driving straight at him!',
      'A messenger shoves an envelope in your hand, nods at you and runs off to his next delivery. You look down to find that the envelope has someone else’s name on it! You notice that the envelope also bears the seal of your long-time enemy…It looks like the seal was poorly done though. You could possibly open the envelope and re-seal it without anyone noticing',
      'A strange courier confronts the party unexpectedly. They bear a note with a strange seal, inviting the adventurers to a dinner party in a remote manion. It promises the opportunity of a lifetime.',
      'On a billboard you find a job offer to kill the monster haunting the village.',
      'As you enter a rarely visited frontier town, your party notices something strange; there are no adults anywhere, only children.',
    ],
  },
};