import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { RouteNavigationService } from '../route-navigation.service';
import { Store } from '@ngxs/store';
import { ApplicationStateModel, StoreState } from '../../../app.state';
import { FeatureSwitch } from '../../../../../../server/src/subscriptions/models/feature-switch.enum';

@Injectable()
export class FeatureSwitchRouteGuard implements CanActivate {
  constructor(private _store: Store,
              private _routeNavigationService: RouteNavigationService) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const applicationState = this._store.selectSnapshot<ApplicationStateModel>((state: StoreState) => state.applicationState);

    if (!applicationState.user || !applicationState.userFeatureSwitches) {
      return false;
    }

    const requiredFeatureSwitches = <FeatureSwitch[]>route.data.requiredFeatureSwitches;

    if (!requiredFeatureSwitches) {
      return true;
    }

    return !requiredFeatureSwitches.some(featureSwitch => !applicationState.userFeatureSwitches.includes(featureSwitch));
  }
}
