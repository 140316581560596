import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserDTO } from '../../../server/src/users/dto/users.dto';
import { FeatureSwitch } from '../../../server/src/subscriptions/models/feature-switch.enum';
import { SubscriptionLimitations } from '../../../server/src/subscriptions/models/subscription.enum';

export interface StoreState {
  applicationState: ApplicationStateModel;
}

export class SetUserAction {
  static readonly type = '[Application] Set User';

  constructor(public user: UserDTO | undefined,
              public subscriptionLimitations: SubscriptionLimitations | undefined,
              public userFeatureSwitches: FeatureSwitch[]) {

  }
}

export interface ApplicationStateModel {
  user?: UserDTO;

  userFeatureSwitches: FeatureSwitch[];

  subscriptionLimitations?: SubscriptionLimitations;
}

@State<ApplicationStateModel>({
  name: 'applicationState',
  defaults: {
    user: undefined,
    userFeatureSwitches: [],
    subscriptionLimitations: undefined,
  },
})
@Injectable()
export class ApplicationState {
  constructor() {
  }

  @Action(SetUserAction)
  public setUser(ctx: StateContext<ApplicationStateModel>, action: SetUserAction) {
    ctx.patchState({
      user: action.user,
      userFeatureSwitches: action.userFeatureSwitches,
      subscriptionLimitations: action.subscriptionLimitations,
    });
  }
}
