import { MessageType } from '../message-bus/message';

export type TrackingEventCategory = 'Message Campaign' | 'NPCs' | 'Items' | 'Campaigns' | 'Misc' | 'Landing' | 'Account' | 'Characters' | 'Worlds' | 'Tools';

export const TrackingEvents: {
  triggerMessageType: MessageType;
  eventName: string;
  eventCategory: TrackingEventCategory;
  passPayload?: boolean;
}[] = [
  { triggerMessageType: MessageType.ButtonClick, eventName: 'Click', eventCategory: 'Misc', passPayload: true },

  { triggerMessageType: MessageType.CharacterAbilityAdded, eventName: 'Character ability added', eventCategory: 'Characters',  },
  { triggerMessageType: MessageType.CharacterCreated, eventName: 'Character created', eventCategory: 'Characters' },
  { triggerMessageType: MessageType.CharacterRemoved, eventName: 'Remove character', eventCategory: 'Characters' },

  { triggerMessageType: MessageType.CharacterHitPointsIncreased, eventName: 'Increase hit point', eventCategory: 'Characters' },
  { triggerMessageType: MessageType.CharacterHitPointsDecreased, eventName: 'Decrease hit point', eventCategory: 'Characters' },
  { triggerMessageType: MessageType.CharacterActionPointsIncreased, eventName: 'Increase action point', eventCategory: 'Characters' },
  { triggerMessageType: MessageType.CharacterActionPointsDecreased, eventName: 'Decrease action point', eventCategory: 'Characters' },

  { triggerMessageType: MessageType.CharacterAbilityAdded, eventName: 'Add ability', eventCategory: 'Characters' },
  { triggerMessageType: MessageType.CharacterAbilityRemoved, eventName: 'Remove ability', eventCategory: 'Characters' },

  { triggerMessageType: MessageType.CharacterBuiltInItemAdded, eventName: 'Add built-in item', eventCategory: 'Characters' },
  { triggerMessageType: MessageType.CharacterCustomItemAdded, eventName: 'Add custom item', eventCategory: 'Characters' },
  { triggerMessageType: MessageType.CharacterItemRemoved, eventName: 'Remove item', eventCategory: 'Characters' },

  { triggerMessageType: MessageType.WorldCreated, eventName: 'World created', eventCategory: 'Worlds' },
  { triggerMessageType: MessageType.WorldRemoved, eventName: 'Remove world', eventCategory: 'Worlds' },

  { triggerMessageType: MessageType.DiceRoll, eventName: 'Roll dice', eventCategory: 'Tools' },
  { triggerMessageType: MessageType.DiceReRoll, eventName: 'Re-roll dice', eventCategory: 'Tools' },
  { triggerMessageType: MessageType.ToughChoice, eventName: 'Tough choice', eventCategory: 'Tools' },
  { triggerMessageType: MessageType.ToughChoiceReGenerate, eventName: 'Re-generate choice', eventCategory: 'Tools' },

  { triggerMessageType: MessageType.Logout, eventName: 'Logout', eventCategory: 'Account' },

  { triggerMessageType: MessageType.ShowMessageCampaing, eventName: 'Shown message', eventCategory: 'Message Campaign', passPayload: true },
  { triggerMessageType: MessageType.ClickMessageCampaing, eventName: 'Click message button', eventCategory: 'Message Campaign', passPayload: true },
  { triggerMessageType: MessageType.CloseMessageCampaing, eventName: 'Close message', eventCategory: 'Message Campaign', passPayload: true },

  { triggerMessageType: MessageType.CampaignCreated, eventName: 'Campaign created', eventCategory: 'Campaigns' },
  { triggerMessageType: MessageType.CampaignAdventureCreated, eventName: 'Campaign adventure created', eventCategory: 'Campaigns' },
  { triggerMessageType: MessageType.CampaignRemoved, eventName: 'Remove campaign', eventCategory: 'Campaigns' },

  { triggerMessageType: MessageType.CampaignSessionCreated, eventName: 'Campaign session created', eventCategory: 'Campaigns' },
  { triggerMessageType: MessageType.CampaignSessionRemoved, eventName: 'Remove campaign session', eventCategory: 'Campaigns' },

  { triggerMessageType: MessageType.NPCCreated, eventName: 'NPC created', eventCategory: 'NPCs' },
  { triggerMessageType: MessageType.NPCRemoved, eventName: 'Remove NPC', eventCategory: 'NPCs' },

  { triggerMessageType: MessageType.NPCCreated, eventName: 'Item created', eventCategory: 'Items' },
  { triggerMessageType: MessageType.NPCRemoved, eventName: 'Remove item', eventCategory: 'Items' },
];
