<div class="h-100">
  <div id="header-container">
    <app-header></app-header>
  </div>

  <div id="page-container" class="px-4 px-lg-5">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
