import { MessageType } from '../../../../common/services/message-bus/message';
import { RoutePage } from '../../../../common/routing/route-pages.enum';

export enum MessageTriggerRestrictionType {
  DateAfter,
  DateBefore,
  MinDaysSinceLastShow,
  MaxShown,
  MaxButtonClicked,
}

export interface MessageCampaign {
  id: string;

  name: string;

  triggerEvents: { messageType: MessageType; payloadFilter?: (payload: any) => boolean; }[],

  triggerRestrictions: {
    type: MessageTriggerRestrictionType;

    value?: number | Date;
  }[];

  content: {
    title: string;

    body: string;

    buttonText: string;

    buttonLink?: string;

    buttonTarget: '_blank' | '_top';
  };
}

export const MessageCampaigns: MessageCampaign[] = [
  /*{
    id: '1',
    name: 'Future form',
    triggerEvents: [
      {
        messageType: MessageType.PageView,
        payloadFilter: payload => [RoutePage.CharacterList, RoutePage.WorldList].includes(payload.page),
      },
    ],
    triggerRestrictions: [
      { type: MessageTriggerRestrictionType.MinDaysSinceLastShow, value: 1 },
      { type: MessageTriggerRestrictionType.MaxShown, value: 3 },
      { type: MessageTriggerRestrictionType.MaxButtonClicked, value: 1 },
    ],
    content: {
      title: 'What features would you like to see in QuestCompanion?',
      body: 'Participate in deciding the future of QuestCompanion by giving feedback on features you would like to use. It only takes 30 seconds.',
      buttonText: 'Give feedback',
      buttonLink: 'https://forms.gle/tQzTr5EqMCk9Zv5p7',
      buttonTarget: '_blank',
    },
  },*/
  {
    id: '2',
    name: 'Patreon support',
    triggerEvents: [
      {
        messageType: MessageType.PageView,
        payloadFilter: payload => [
          RoutePage.CharacterList,
          RoutePage.WorldList,
          RoutePage.NPCPage,
          RoutePage.ItemPage,
        ].includes(payload.page),
      },
    ],
    triggerRestrictions: [
      { type: MessageTriggerRestrictionType.MinDaysSinceLastShow, value: 1 },
      { type: MessageTriggerRestrictionType.MaxShown, value: 2 },
      { type: MessageTriggerRestrictionType.MaxButtonClicked, value: 1 },
    ],
    content: {
      title: 'Support us',
      body: 'If you like QuestCompanion please consider supporting us, so that we can pursue our goal to create the all-in-one companion app for your TTRPG adventures.',
      buttonText: 'Support us',
      buttonLink: 'https://www.patreon.com/questcompanion',
      buttonTarget: '_blank',
    },
  },
];


export interface MessageCampaignState {
  campaignId: string;

  lastShown: Date | null;

  numberOfShows: number;

  numberOfClicks: number;
}
