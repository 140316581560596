import { NgModule } from '@angular/core';
import { MessageCampaignsService } from './shared/services/message-campaigns.service';
import { PopupMessageComponent } from './popup-message/popup-message.component';
import { AppCommonModule } from '../../common/common.module';
import { MessageCampaignStateManager } from './shared/services/message-campaign-state.manager';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    PopupMessageComponent,
  ],
  imports: [
    AppCommonModule,
  ],
  providers: [
    MessageCampaignsService,
    MessageCampaignStateManager,
  ]
})
export class MessagingModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faTimes,
    );
  }
}
