import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngxs/store';
import { RouteNavigationService } from '../routing/route-navigation.service';
import { SetUserAction } from '../../app.state';
import { UserDTO } from '../../../../../server/src/users/dto/users.dto';
import { ENVIRONMENT } from './injection-tokens';
import { Environment } from '../../../environments/environment.interface';
import { FeatureSwitch } from '../../../../../server/src/subscriptions/models/feature-switch.enum';
import { UserSettingsService } from './user-settings.service';
import { SubscriptionLimitations } from '../../../../../server/src/subscriptions/models/subscription.enum';

@Injectable()
export class AuthenticationService {
  private _userSubject = new ReplaySubject<UserDTO | undefined>(1);

  constructor(private _store: Store,
              private _userSettingsService: UserSettingsService,
              @Inject(ENVIRONMENT) private _environment: Environment,
              private _routeNavigationService: RouteNavigationService) {

  }

  public async initializeAsync() {
    await this.refreshUserStateAsync();
  }

  public async refreshUserStateAsync() {
    let user: UserDTO | undefined = undefined;
    let userFeatureSwitches: FeatureSwitch[] = [];
    let subscriptionLimitations: SubscriptionLimitations | undefined = undefined;

    try {
      const userSettings = await this._userSettingsService.getUserSettingsAsync();

      user = userSettings?.user;
      userFeatureSwitches = userSettings?.featureSwitches;
      subscriptionLimitations = userSettings?.limitations;
    } catch {

    }

    this._userSubject.next(user);

    this._store.dispatch(new SetUserAction(user, subscriptionLimitations, userFeatureSwitches));
  }

  public async loginAsync(loginType: 'google' | 'facebook' | 'apple' = 'google') {
    const w = <any>window;

    switch (loginType) {
      case 'google':
        w.top.location.href = `${ this._environment.backendUrl }/api/authentications/google`;

        break;

      case 'facebook':
        w.top.location.href = `${ this._environment.backendUrl }/api/authentications/facebook`;

        break;

      case 'apple':
        w.top.location.href = `${ this._environment.backendUrl }/api/authentications/apple`;

        break;

      default:
        throw new Error(`Unknown login type: ${ loginType }`);
    }
  }

  public async logoutAsync() {
    (<any>window).top.location.href = `${ this._environment.backendUrl }/api/authentications/logout`;
  }
}
