import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../../common/routing/route-navigation.service';
import { Select } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { MessageType } from '../../../common/services/message-bus/message';
import { TriggerMessage } from '../../../common/decorators/trigger-message.decorator';
import { UserDTO } from '../../../../../../server/src/users/dto/users.dto';

@UntilDestroy()
@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMenuComponent implements OnInit {

  constructor(private _authenticationService: AuthenticationService,
              private _routeNavigationService: RouteNavigationService,
              private _changeDetector: ChangeDetectorRef) {

  }

  public user?: UserDTO;

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  public ngOnInit(): void {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.user = state.user;

        this._changeDetector.markForCheck();
      });
  }

  @TriggerMessage(MessageType.Logout)
  public async logout() {
    await this._authenticationService.logoutAsync();
  }

  public async goToProfileSettingsAsync() {
    await this._routeNavigationService.goToProfileSettingsPageAsync();
  }
}
