import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteLocations } from './common/routing/route-locations.enum';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { LoggedInRouteGuard } from './common/routing/route-guards/logged-in.guard';

const routes: Routes = [
  {
    path: RouteLocations.Home,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: RouteLocations.About,
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
  },
  {
    path: RouteLocations.Companion,
    loadChildren: () => import('./companion/companion.module').then(m => m.CompanionModule),
    canActivate: [LoggedInRouteGuard],
  },
  {
    path: RouteLocations.Login,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: RouteLocations.NotFound,
    component: NotFoundComponent,
  },
  {
    path: 'privacy',
    redirectTo: [RouteLocations.About, RouteLocations.AboutChildren.PrivacyPolicy].join('/'),
  },
  {
    path: 'terms',
    redirectTo: [RouteLocations.About, RouteLocations.AboutChildren.TermsAndConditions].join('/'),
  },
  {
    path: '**',
    redirectTo: RouteLocations.Home,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
