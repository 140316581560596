export abstract class ObjectUtils {
  public static deepCopy<T>(obj: T): T {
    if (!obj) {
      return obj;
    }

    return JSON.parse(JSON.stringify(obj));
  }

  public static stripUndefinedProperties<T>(obj: any): T {
    return this.deepCopy(obj);
  }

  public static mergeDeep<T>(target: any, source: any): T {
    let output: any = Object.assign({}, target);

    if (this.isObject(target) && this.isObject(source)) {
      Object.entries(source).forEach(([key, value]) => {
        if (this.isObject(value)) {
          if (!(key in target)) {
            Object.assign(output, { [key]: value });
          } else {
            output[key] = this.mergeDeep(target[key], value);
          }
        } else {
          Object.assign(output, { [key]: value });
        }
      });
    }

    return output;
  }

  public static mergeDeepDefined<T>(target: any, source: any): T {
    const undefinedRemoved = this.stripUndefinedProperties(source);

    return this.mergeDeep(target, undefinedRemoved);
  }

  public static isObject(obj: any): boolean {
    return (obj && typeof obj === 'object' && !Array.isArray(obj));
  }

  public static construct<T extends object>(type: new () => T, changes: Partial<T>): T {
    return <T>Object.assign(new type(), changes);
  }
}
