import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../common/routing/route-navigation.service';
import { Observable } from 'rxjs';
import { ApplicationState, ApplicationStateModel } from '../../app.state';
import { Select } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { DiceDialogComponent } from './dice-dialog/dice-dialog.component';
import { ToughChoiceDialogComponent } from './tough-choice-dialog/tough-choice-dialog.component';
import { TriggerMessage } from '../../common/decorators/trigger-message.decorator';
import { MessageType } from '../../common/services/message-bus/message';
import { FeatureSwitch } from '../../../../../server/src/subscriptions/models/feature-switch.enum';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  constructor(private _changeDetector: ChangeDetectorRef,
              private _dialogService: MatDialog,
              private _routeNavigationService: RouteNavigationService) {

  }

  public FeatureSwitch = FeatureSwitch;

  public isUserLoggedIn: boolean = false;

  public landingUrl: string = this._routeNavigationService.getUrlForLanding();

  public loginUrl: string = this._routeNavigationService.getUrlForLogin();
  public registerUrl: string = this._routeNavigationService.getUrlForRegistration();

  public charactersUrl: string = this._routeNavigationService.getUrlForCharacters();
  public npcsUrl: string = this._routeNavigationService.getUrlForNPCList();
  public worldsUrl: string = this._routeNavigationService.getUrlForWorlds();
  public campaignsUrl: string = this._routeNavigationService.getUrlForCampaigns();
  public adventuresUrl: string = this._routeNavigationService.getUrlForAdventures();
  public itemsUrl: string = this._routeNavigationService.getUrlForItems();

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  ngOnInit(): void {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.isUserLoggedIn = !!state.user;

        this._changeDetector.markForCheck();
      });
  }

  @TriggerMessage(MessageType.DiceRoll)
  public async rollDiceAsync() {
    await this._dialogService.open(DiceDialogComponent);
  }

  @TriggerMessage(MessageType.ToughChoice)
  public async toughChoiceAsync() {
    await this._dialogService.open(ToughChoiceDialogComponent);
  }
}
