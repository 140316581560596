<div [matMenuTriggerFor]="menu" class="flex-row-center user-select-none cursor-pointer">
  <img *ngIf="user?.photo" [src]="user?.photo?.url">

  <div class="ml-2">{{ user?.name }}</div>

  <fa-icon class="ml-2" icon="caret-down"></fa-icon>
</div>

<mat-menu #menu="matMenu" xPosition="after">
  <button mat-menu-item (click)="goToProfileSettingsAsync()">Settings</button>

  <button mat-menu-item (click)="logout()">Sign out</button>
</mat-menu>

