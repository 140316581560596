import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import sample from 'lodash/sample';
import { TriggerMessage } from '../../../common/decorators/trigger-message.decorator';
import { MessageType } from '../../../common/services/message-bus/message';
import { SUGGESTIONS } from '../../../../../../common/dto/suggestions';

@Component({
  selector: 'app-tough-choice-dialog',
  templateUrl: './tough-choice-dialog.component.html',
  styleUrls: ['./tough-choice-dialog.component.scss'],
})
export class ToughChoiceDialogComponent implements OnInit {
  constructor(private _dialogRef: MatDialogRef<ToughChoiceDialogComponent>) {
  }

  public choices: { value: string; }[] = [
    { value: '' },
    { value: '' },
  ];

  public ngOnInit() {
    for (const choice of this.choices) {
      this._generateChoice(choice);
    }
  }

  public close() {
    this._dialogRef.close();
  }

  @TriggerMessage(MessageType.ToughChoiceReGenerate)
  public reGenerateChoice(choice: { value: string }) {
    this._generateChoice(choice);
  }

  private _generateChoice(choice: { value: string }) {
    choice.value = sample(SUGGESTIONS.ToughChoices)!;
  }
}
