import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageCampaign } from '../shared/services/message-campaigns';
import { MessageBusService } from '../../../common/services/message-bus/message-bus.service';
import { MessageType } from '../../../common/services/message-bus/message';
import { MessageCampaignStateManager } from '../shared/services/message-campaign-state.manager';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupMessageComponent implements OnInit {

  constructor(private _dialog: MatDialogRef<PopupMessageComponent>,
              private _messageBus: MessageBusService,
              private _campaignStateManager: MessageCampaignStateManager,
              @Inject(MAT_DIALOG_DATA) private _dialogData: { campaign: MessageCampaign }) {
  }

  public campaign!: MessageCampaign;

  ngOnInit(): void {
    this.campaign = this._dialogData.campaign;
  }

  public onButtonClick() {
    this._campaignStateManager.increaseClickCount(this.campaign.id);

    this._messageBus.dispatch({ type: MessageType.ClickMessageCampaing, payload: { campaign: this.campaign.name } });
  }

  public close() {
    this._messageBus.dispatch({ type: MessageType.CloseMessageCampaing, payload: { campaign: this.campaign.name } });

    this._dialog.close();
  }
}
