export const RouteLocations = {
  Home: 'home',
  HomeChildren: {
    Landing: '',
    Pricing: 'pricing',
  },

  Login: 'login',
  LoginChildren: {
    LoginPage: '',
    Registration: 'register',
  },

  Companion: 'companion',
  CompanionChildren: {
    Characters: 'characters',
    CharactersChildren: {
      List: '',
      PlayerCharacter: ':characterId',
      Creator: 'creator',
    },

    NPCs: 'npcs',
    NPCsChildren: {
      List: '',
      NPCPage: ':npcId',
    },

    Profile: 'profile',
    ProfileChildren: {
      ProfilePage: '',
    },

    Worlds: 'worlds',
    WorldsChildren: {
      List: '',
      World: ':worldId',
      Creator: 'creator',
    },

    Campaigns: 'campaigns',
    CampaignsChildren: {
      List: '',
      Campaign: ':campaignId',
      CampaignSession: ':campaignId/sessions/:sessionId',
      CampaignAdventureEpisodePage: ':campaignId/adventure/episodes/:episodeId',
    },

    Adventures: 'adventures',
    AdventuresChildren: {
      List: '',
      AdventurePage: ':adventureId',
      AdventureEpisodePage: ':adventureId/episodes/:episodeId',
    },

    Items: 'items',
    ItemsChildren: {
      List: '',
      ItemPage: ':itemId',
    },
  },

  About: 'about',
  AboutChildren: {
    TermsAndConditions: 'terms',
    PrivacyPolicy: 'privacy',
  },

  NotFound: 'not-found',
};
