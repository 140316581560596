export enum MessageType {
  CharacterCreated,
  CharacterRemoved,
  CharacterEdited,

  CharacterBuiltInItemAdded,
  CharacterCustomItemAdded,
  CharacterItemRemoved,

  CharacterAbilityAdded,
  CharacterAbilityRemoved,

  CharacterActionPointsIncreased,
  CharacterActionPointsDecreased,

  CharacterHitPointsIncreased,
  CharacterHitPointsDecreased,

  WorldCreated,
  WorldRemoved,
  WorldEdited,

  Logout,
  DiceRoll,
  ToughChoice,
  ToughChoiceReGenerate,

  DiceReRoll,
  ButtonClick,
  PageView,
  ShowMessageCampaing,
  CloseMessageCampaing,
  ClickMessageCampaing,

  CampaignCreated,
  CampaignRemoved,
  CampaignAdventureCreated,

  CampaignSessionCreated,
  CampaignSessionRemoved,

  NPCCreated,
  NPCRemoved,

  ItemCreated,
  ItemRemoved,

  NPCAddedToCampaign,
  NPCRemovedFromCampaign,
  NPCHiddenInCampaign,
  NPCShownInCampaign,
}

export interface Message<T extends MessageType> {
  type: T;

  payload?: any;
}
