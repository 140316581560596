<footer class="px-4 px-lg-5 pt-5 pb-4">
  <div class="row">
    <div class="col-12 col-md-8">
      <app-logo color="white"></app-logo>

      <div class="mt-3 flex-row-center">
        <a class="text-white" [href]="socialLinks?.twitterUrl" target="_blank" title="Follow QuestCompanion on Twitter">
          <fa-icon [icon]="['fab', 'twitter']" size="lg"></fa-icon>
        </a>
        <a class="text-white ml-4" [href]="socialLinks?.discordUrl" target="_blank" title="Join our Discord channel">
          <fa-icon [icon]="['fab', 'discord']" size="lg"></fa-icon>
        </a>
        <a class="text-white ml-4" [href]="socialLinks?.emailUrl" target="_blank" title="Write us">
          <fa-icon icon="envelope" size="lg"></fa-icon>
        </a>
      </div>
    </div>

    <div class="col-12 col-md-4 mt-5 mt-md-0 d-flex flex-column flex-md-row justify-content-md-center">
      <a class="mt-3 mr-4" [routerLink]="pricingUrl">Pricing</a>

      <a class="mt-3 mr-4" [routerLink]="pricingUrl">Support us</a>

      <a class="mt-3 mr-4" [routerLink]="termsUrl">Terms</a>

      <a class="mt-3" [routerLink]="privacyUrl">Privacy</a>
    </div>
  </div>

  <div class="mt-5 copyright">
    <div>&copy; {{ year }} Quest Companion</div>

    <div class="external">
      <a href="https://adventure.game" target="_blank">Quest</a> and related trademarks are property of <a
      href="https://adventure.game" target="_blank">Adventure Guild LLC</a>
    </div>
  </div>
</footer>
