import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteNavigationService } from '../../../common/routing/route-navigation.service';
import { Select } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { DiceDialogComponent } from '../dice-dialog/dice-dialog.component';
import { ToughChoiceDialogComponent } from '../tough-choice-dialog/tough-choice-dialog.component';
import { TriggerMessage } from '../../../common/decorators/trigger-message.decorator';
import { MessageType } from '../../../common/services/message-bus/message';
import { FeatureSwitch } from '../../../../../../server/src/subscriptions/models/feature-switch.enum';

@UntilDestroy()
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent implements OnInit {

  constructor(private _authenticationService: AuthenticationService,
              private _dialogService: MatDialog,
              private _routeNavigationService: RouteNavigationService,
              private _changeDetector: ChangeDetectorRef) {

  }

  public FeatureSwitch = FeatureSwitch;

  public isOpen: boolean = false;

  public isUserLoggedIn: boolean = false;

  public loginUrl: string = this._routeNavigationService.getUrlForLogin();
  public registerUrl: string = this._routeNavigationService.getUrlForRegistration();

  public charactersUrl: string = this._routeNavigationService.getUrlForCharacters();
  public npcsUrl: string = this._routeNavigationService.getUrlForNPCList();
  public worldsUrl: string = this._routeNavigationService.getUrlForWorlds();
  public campaignsUrl: string = this._routeNavigationService.getUrlForCampaigns();
  public adventuresUrl: string = this._routeNavigationService.getUrlForAdventures();
  public itemsUrl: string = this._routeNavigationService.getUrlForItems();

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  public ngOnInit(): void {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this.isUserLoggedIn = !!state.user;

        this._changeDetector.markForCheck();
      });
  }

  public toggle() {
    this.isOpen = !this.isOpen;

    this._changeDetector.markForCheck();
  }

  public open() {
    this.isOpen = true;

    this._changeDetector.markForCheck();
  }

  public close() {
    this.isOpen = false;

    this._changeDetector.markForCheck();
  }

  @TriggerMessage(MessageType.DiceRoll)
  public async rollDiceAsync() {
    await this._dialogService.open(DiceDialogComponent);
  }

  @TriggerMessage(MessageType.ToughChoice)
  public async toughChoiceAsync() {
    await this._dialogService.open(ToughChoiceDialogComponent);
  }
}
