import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { WINDOW } from './common/services/injection-tokens';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private _router: Router,
              @Inject(WINDOW) private _window: Window) {

  }

  public ngOnInit() {
    this._router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this._window.scrollTo(0, 0);
      });
  }
}
