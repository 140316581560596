import { FileDTO } from '../../../../../server/src/files/dto/file.dto';
import { ObjectUtils } from '../../../../../common/utils/object.utils';

export abstract class FileUploadRequestUtils {
  public static createRequestForm<T>(
    dataName: string,
    item: T,
    imageResolver: (item: T) => FileDTO | undefined | null,
  ): FormData {
    const formData = new FormData();

    const originalImage = imageResolver(item);

    if (originalImage?.file) {
      formData.append(`image`, <Blob><any>originalImage.file);
    }

    const copyOfItem = ObjectUtils.deepCopy(item);

    const copyOfImage = imageResolver(copyOfItem);

    if (copyOfImage && !copyOfImage.id) {
      copyOfImage.file = undefined;
      copyOfImage.url = '';
    }

    formData.append(dataName, JSON.stringify(copyOfItem));

    return formData;
  }
}
