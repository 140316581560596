import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { environment } from '../../environments/environment';
import { AppCommonModule } from '../common/common.module';
import { GlobalErrorHandler } from '../common/services/logger/global-error-handler';
import { FooterComponent } from './footer/footer.component';
import { AccountMenuComponent } from './header/account-menu/account-menu.component';
import { HeaderComponent } from './header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { StartupService, startupServiceFactory } from './shared/services/startup/startup.service';
import {
  faBars,
  faCaretDown,
  faCertificate,
  faDiceD20,
  faEnvelope,
  faSyncAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { SideMenuComponent } from './header/side-menu/side-menu.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DiceDialogComponent } from './header/dice-dialog/dice-dialog.component';
import { faDiscord, faPatreon, faTrello, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { ToughChoiceDialogComponent } from './header/tough-choice-dialog/tough-choice-dialog.component';
import { MessagingModule } from './messaging/messaging.module';
import { UnauthenticatedInterceptor } from './shared/services/startup/unauthnticated.interceptor';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AccountMenuComponent,
    SideMenuComponent,
    NotFoundComponent,
    DiceDialogComponent,
    ToughChoiceDialogComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,

    MatDialogModule,
  ],
  imports: [
    AppCommonModule,

    MessagingModule,

    MatMenuModule,
    MatDialogModule,
  ],
  providers: [
    StartupService,
    UnauthenticatedInterceptor,

    { provide: ErrorHandler, useClass: environment.logging?.enabled ? GlobalErrorHandler : ErrorHandler },
    { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [StartupService], multi: true },
  ],
})
export class AppCoreModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faCaretDown,
      faBars,
      faTimes,
      faDiceD20,
      faCertificate,
      faTwitter,
      faPatreon,
      faTrello,
      faDiscord,
      faEnvelope,
      faSyncAlt,
    );
  }
}
